import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  button: {
    border: '1px solid rgba(20, 20, 20, 0.12)',
    color: 'rgb(20, 20, 20)',
    marginTop: 0,

    span: {
      color: 'rgba(20, 20, 20, 0.56)',
    },
    '&::placeholder': {
      color: 'rgba(20, 20, 20, 0.56)',
    },
    '&:hover': {
      border: '1px solid rgba(20, 20, 20, 0.12)',
    },
    '&:focus': {
      border: '1px solid rgba(20, 20, 20, 0.12)',
    },
  },
});
