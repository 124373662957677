import { Grid } from '@hexa-ui/components';
import { List2, Rules, DocumentSigned, DollarSign } from '@hexa-ui/icons';
import {
  setAppHeaderConfig,
  useAppHeader,
  useHasPermission,
  useSidebar,
  useUserMetadata,
} from 'admin-portal-shared-services';
import { useAdapters } from 'contexts/AdaptersContext';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { BASE_PATH, RECONCILIATION_PATH, TRANSACTIONS_PATH, WORKFLOW_PATH } from 'src/routes';
import { setCountry } from 'store/slices/reports.slice';
import { AppbarConfigProps } from 'types/appBarConfig';
import { useEnvContext } from 'utils/EnvProvider/EnvProvider';
import { isFeatureEnabled } from 'utils/featureToggles';

export const sidebarIcons = {
  RulesIcon: () => <Rules />,
  DollarSignIcon: () => <DollarSign />,
  List2Icon: () => <List2 />,
  DocumentSignedIcon: () => <DocumentSigned />,
};

export const AppbarConfig = ({ children }: AppbarConfigProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { REACT_APP_HOME_PATH } = useEnvContext();
  const [isUpdated, setIsUpdated] = useState(false);
  const { data, isLoading } = useUserMetadata();
  const [{ selectedCountry }] = useAppHeader();
  const dispatch = useDispatch();
  const [countriesOptions, setCountriesOptions] = useState<string[]>([]);
  const { pathSegments } = useAdapters();

  const hasReadWorkflowAuthorization = useHasPermission('GatewayWeb.Workflow.Read');
  const hasWriteWorkflowAuthorization = useHasPermission('GatewayWeb.Workflow.Write');

  const getBreadcrumbConfig = useCallback(() => {
    const breadcrumbItems = pathSegments.map((segment, index) => {
      if (segment === 'bees-gateway-web') {
        return {
          label: formatMessage({ id: 'Commons.portal' }),
          path: `/${pathSegments.slice(0, index + 1).join('/')}`,
          isCurrentPage: index === pathSegments.length - 1,
        };
      }

      const formattedSegment = segment.replace(/-/g, ' ');

      return {
        label: formattedSegment.charAt(0).toUpperCase() + formattedSegment.slice(1),
        path: `/${pathSegments.slice(0, index + 1).join('/')}`,
        isCurrentPage: index === pathSegments.length - 1,
      };
    });

    return {
      homePath: `/${REACT_APP_HOME_PATH}`,
      items: [...breadcrumbItems],
    };
  }, [pathSegments, REACT_APP_HOME_PATH]);

  useEffect(() => {
    const breadcrumbConfig = getBreadcrumbConfig();
    const pageTitle = ' ';
    setAppHeaderConfig({
      breadcrumbConfig,
      pageTitle,
    });
  }, [pathSegments, getBreadcrumbConfig]);

  useEffect(() => {
    if (!isLoading && data?.supportedCountries) {
      const sortedCountries = [...data.supportedCountries].sort((a, b) => a.localeCompare(b));
      setCountriesOptions(sortedCountries);
    }
  }, [isLoading, data, isUpdated, selectedCountry]);

  // TODO: REMOVER ESTE DISPATCH APOS CONCLUSAO DO ADMINV2
  useEffect(() => {
    dispatch(setCountry(selectedCountry));
  }, [selectedCountry, dispatch]);

  useEffect(() => {
    if (!isLoading && data && !isUpdated && countriesOptions.length > 0) {
      setAppHeaderConfig({
        countrySelect: true,
        defaultCountry: selectedCountry,
        countryOptions: countriesOptions,
      });
      setIsUpdated(true);
    }
  }, [data, isUpdated, isLoading, countriesOptions, selectedCountry]);

  const getTitle = (id: string): string => {
    try {
      return formatMessage({ id });
    } catch (error: unknown) {
      console.error(`Error formatting message for id: ${id}`, error);
      return id;
    }
  };

  const items = [
    isFeatureEnabled('transactions') && {
      id: 'transactions',
      title: getTitle('PageTitles.TRANSACTIONS'),
      icon: sidebarIcons.DollarSignIcon,
      path: `${TRANSACTIONS_PATH}`,
    },
    isFeatureEnabled('reconciliation') && {
      id: 'reconciliation',
      title: getTitle('PageTitles.RECONCILIATION'),
      icon: sidebarIcons.DocumentSignedIcon,
      path: `${RECONCILIATION_PATH}`,
    },
    {
      id: 'payments',
      title: getTitle('PageTitles.PAYMENTS'),
      icon: sidebarIcons.RulesIcon,
      path: `${BASE_PATH}`,
    },
    isFeatureEnabled('workflow') && (hasReadWorkflowAuthorization || hasWriteWorkflowAuthorization) && {
      id: 'workflow',
      title: getTitle('PageTitles.WORKFLOW'),
      icon: sidebarIcons.List2Icon,
      path: `${WORKFLOW_PATH}`,
    },
  ].filter(Boolean);

  useSidebar({
    items,
    utils: [],
  });

  return (
    <Grid.Container
      style={{
        rowGap: '1rem',
      }}
      type="fluid"
      sidebar
    >
      {children}
    </Grid.Container>
  );
};
