import { IDayOfTheWeek } from 'types/schedule';

export const daysOftheWeek: IDayOfTheWeek[] = [
  {
    id: 1,
    name:  'MONDAY',
    value: 'MONDAY',
  },
  {
    id: 2,
    name: 'TUESDAY',
    value: 'TUESDAY',
  },
  {
    id: 3,
    name: 'WEDNESDAY',
    value: 'WEDNESDAY',
  },
  {
    id: 4,
    name: 'THURSDAY',
    value: 'THURSDAY',
  },
  {
    id: 5,
    name: 'FRIDAY',
    value: 'FRIDAY',
  },
];
