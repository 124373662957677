import { IOption } from 'types/filterBar';

export const translateArray = (
  items: IOption[],
  formatMessage: (message: { id: string }) => string,
  labelField: string = 'label'
): IOption[] => {
  return items.map((item) => {
    try {
      const id = item.idName || item.name || item.accessor;
      if (!id) {
        console.error('Error: idName or accessor is missing for item', item);
        return item;
      }

      if (item[labelField]) {
        if(item.key){
          return {
            ...item,
            [labelField]: formatMessage({ id: `table.headers.${id}` }),
          };
        }
        return {
          ...item,
          [labelField]: formatMessage({ id: `filter.labels.${id}` }),
        };
      } else if (item.Header) {
        return {
          ...item,
          Header: formatMessage({ id: `table.headers.${id}` }),
        };
      } else if (item.name) {
        return {
          ...item,
          name: formatMessage({ id: `resolvedRequestsPage.scheduleNew.daysOfTheWeek.name.${id}` }),
        };
      } else {
        console.warn('Warning: No labelField or Header found for item', item);
      }
    } catch (error) {
      console.error(`Error translating item with id: ${item.idName || item.accessor}`, error);
    }
    return item;
  });
};
