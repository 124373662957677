import { useMemo } from 'react';
import { translateArray } from 'utils/translateArray';
import { Select } from '@hexa-ui/components';
import { useIntl } from 'react-intl';

import { daysOftheWeek } from 'constants/daysOftheWeek';
import { IDayOfTheWeekProps } from 'types/schedule';
import * as S from './DayOfTheWeek.styles';

export const DayOfTheWeek = ({ value, onChange, ...rest }: IDayOfTheWeekProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const translateddaysOftheWeek = useMemo(
    () => translateArray(daysOftheWeek, formatMessage),
    [daysOftheWeek, formatMessage]
  );

  const handleChange = (selectedValue: string) => {
    if (!translateddaysOftheWeek.some((day) => day.value === selectedValue)) {
      console.error(`Unexpected value: ${selectedValue}`);
      return;
    }
    onChange(selectedValue);
  };

  return (
    <S.Container>
      <Select.Root
        shape="square"
        data-testid="single-select"
        placeholder={formatMessage({ id: 'resolvedRequestsPage.scheduleNew.selectAoption' })}
        onChange={handleChange}
        {...rest}
      >
        {translateddaysOftheWeek.map((item) => (
          <Select.Option key={item.name} value={item.value}>
            {item.name}
          </Select.Option>
        ))}
      </Select.Root>
    </S.Container>
  );
};
