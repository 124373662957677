import { useEnvContext } from 'utils/EnvProvider';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export const useConfiguredApolloClient = () => {
  const { REACT_APP_API_BFF } = useEnvContext();

  if (!REACT_APP_API_BFF) {
    throw new Error('API endpoint is not defined');
  }

  const httpLink = createHttpLink({
    uri: REACT_APP_API_BFF,
    useGETForQueries: true,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
      },
    };
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  });

  return client;
};
