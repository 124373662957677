import MessageMap from 'i18n/i18n.d';

const en: MessageMap = {
  Commons: {
    actions: 'Actions',
    portal: 'BEES Pay Portal',
  },
  ErrorMessages: {
    DATA_NOT_FOUND: 'Data has not been loaded.',
    ERROR_FETCH_DATA:
      'An error occurred while trying to fetch the data. Refresh the page and try again.',
  },
  PageTitles: {
    WORKFLOW: 'Workflow',
    CREATE: 'New Workflow',
    PAYMENTS: 'Payments',
    TRANSACTIONS: 'Transactions',
    RECONCILIATION: 'Reconciliation',
  },
  resolvedRequestsPage: {
    sendReport: 'Send report',
    sendNow: 'Send now',
    schedule: 'Schedule',
    showScheduled: 'Show scheduled reports',
    newScheduled: 'New Schedule',
    manageScheduled: 'Manage scheduled reports',
    description: 'Access payment information and send filtered reports to your email.',
    results: 'Showing {resultsNumber} results',
    altNoRecordsFound: 'Illustration representing the absence of results in a search.',
    title: 'Title',
    createdOn: 'Created on',
    requester: 'Requester',
    approver: 'Approver',
    pagination: { pageSizeOptions: 'Items per page: {options}' },
    errorsMessages: {
      resultNotFound: 'No results found. Try using different numbers.',
      countryNotFound: 'Country not found. Refresh the page.',
      errorFetchingData: 'Error fetching data.',
      errorConfigUser: 'Your user may not be configured correctly.',
      errorCopyText: 'Failed to copy text to clipboard.',
    },
    editColumnsDropdown: {
      title: 'Edit columns',
      selectAll: 'Select all',
    },
    commom: {
      filter: 'Filter',
      search: 'Search',
    },
    emptyMessage: 'No results found. Try again.',
    toastMessages: {
      paymentIdCopied: 'Payment ID copied.',
    },

    scheduleNew: {
      emptyMessage: "There's no information to show.",
      titleHeader: 'Schedule report delivery',
      description: 'The file containing the filtered data will be sent by email to ',
      message:
        'The report file will contain payments started seven days before the selected weekday.',
      fieldFrequency: 'Frequency',
      subFieldFrequency: 'Sent every week on:',
      fieldDelivery: 'Delivery time',
      deliveryTime: 'Select an option',
      fieldReportName: 'Report name',
      seller: 'Seller',
      method: 'Method',
      delete: 'Delete report',
      deleteDescription: 'The report will be permanently deleted. This action cannot be undone.',
      serviceProvider: 'Service provider',
      orderStatus: 'Order status',
      country: 'Country',
      paymentStatus: 'Payment status',
      columns: 'Columns',
      createDate: 'Creation date',
      filters: 'Filters',
      report: 'Report name',
      placeholder: 'Credit card partially delivered',
      sendButton: 'Schedule',
      cancelButton: 'Cancel',
      settings: 'Settings',
      frequency: 'Frequency',
      dayOfTheWeek: 'Day of the week',
      successCreate: 'Report delivery scheduled.',
      errorCreate: 'Unable to schedule report. Try again.',
      selectAoption: 'Select an option',
      all: 'All',
      daysOfTheWeek: {
        name: {
          MONDAY: 'Every Monday',
          TUESDAY: 'Every Tuesday',
          WEDNESDAY: 'Every Wednesday',
          THURSDAY: 'Every Thursday',
          FRIDAY: 'Every Friday',
        },
      },
      frequencyOptions: {
        daily: {
          title: 'Daily',
          description: 'With payments initiated the previous day.',
        },
        weekly: {
          title: 'Weekly',
          description: 'With payments initiated seven days prior to the selected weekday.',
        },
        every15Days: {
          title: 'Every 15 days',
          description: 'With payments initiated over the past fifteen days.',
        },
        monthly: {
          title: 'Monthly',
          description: 'With payments initiated over the past thirty days.',
        },
        firstDayOfTheMonth: {
          title: 'First day of the month',
          description: 'With payments initiated in the previous month.',
        },
      },
    },
    reportSendNow: {
      title: 'Send now',
      description: 'The file containing the filtered data will be sent by email to ',
      sendButton: 'Send',
      goBackButton: 'Go back',
      successSend: 'The report will be sent by email soon.',
      errorSend: 'Unable to send report. Try again.',
      noSet: 'Not set',
      createDate: 'Creation date',
    },
  },
  countries: {
    BR: 'Brazil',
    MX: 'Mexico',
    CO: 'Colombia',
    EC: 'Ecuador',
    AR: 'Argentina',
  },
  filter: {
    paymentStatus: 'Payment status',
    orderStatus: 'Order status',
    creationDate: 'Creation date',
    createdOn: 'Created on',
    lastEdited: 'Last edited',
    startDate: 'Start date',
    endDate: 'End date',
    endDateHint: 'Leave this field empty to search only by the start date.',
    clearFilters: 'Clear all',
    applyFilters: 'Apply',
    cancel: 'Cancel',
    search: 'Search',
    all: 'All',
    paymentMethod: 'Payment method',
    description: 'The maximum date range is 365 days.',
    filteredBy: 'Filtered by: ',
    messageError: {
      invalidDateRange: 'Invalid date range',
      dateRangeLimit: 'Date range cannot exceed 365 days',
      endDateError: 'End date cannot be earlier than start date',
      invalidDateError: 'Invalid date.',
    },
    labels: {
      paymentStatusExpired: 'Expired',
      paymentStatusFailed: 'Failed',
      paymentStatusPaid: 'Paid',
      orderStatusAvailable: 'Available',
      orderStatusCancelled: 'Cancelled',
      orderStatusConfirmed: 'Confirmed',
      orderStatusDelivered: 'Delivered',
      orderStatusDenied: 'Denied',
      orderStatusExpired: 'Expired',
      orderStatusInvoiced: 'Invoiced',
      orderStatusInTransit: 'In transit',
      orderStatusModified: 'Modified',
      orderStatusPaid: 'Paid',
      orderStatusPartialDelivery: 'Partial delivery',
      orderStatusPending: 'Pending',
      orderStatusPendingCancellation: 'Pending cancelation',
      orderStatusPendingPayment: 'Pending Payment',
      orderStatusPlaced: 'Placed',
    },
  },
  table: {
    headers: {
      paymentId: 'Payment ID',
      orderNumber: 'Order ID',
      orderValue: 'Order value',
      sellerId: 'Seller ID',
      invoiceDate: 'Invoice date',
      accountId: 'POC ID',
      invoiceNumber: 'Invoice ID',
      paymentValue: 'Payment value',
      paymentStatus: 'Payment status',
      orderStatus: 'Order status',
      invoiceValue: 'Invoice value',
      invoiceStatus: 'Invoice status',
      paymentDate: 'Creation date',
      paymentMethod: 'Method',
      paymentMethodCode: 'Service provider',
      beneficiaryName: 'Beneficiary name',
      beneficiaryId: 'Beneficiary ID',
      paymentMethodFee: 'Payment method fee',
      commissionFee: 'Commission fee',
      fileName: 'File Name',
      creationDate: 'Creation Date',
      fileFormat: 'File Format',
      status: 'Status',
      actions: 'Actions'
    },
  },
  pagination: {
    of: 'of',
  },
  statusOrders: {
    canceled: 'Canceled',
    delivered: 'Delivered',
    expired: 'Expired',
    failed: 'Failed',
    error: 'Failed',
    inProgress: 'In progress',
    orderPlaced: 'Order placed',
    outForDelivery: 'Out for delivery',
    paid: 'Paid',
    partiallyDelivered: 'Partially delivered',
    deliveredInfo: 'deliveredInfo',
    expiredInfo: 'expiredInfo',
    paymentPending: 'Payment pending',
    inProgressInfo: 'inProgressInfo',
    orderPlacedInfo: 'orderPlacedInfo',
    outForDeliveryInfo: 'outForDeliveryInfo',
    paidInfo: 'paidInfo',
    partiallyDeliveredInfo: 'partiallyDeliveredInfo',
    paymentPendingInfo: 'paymentPendingInfo',
    errorInfo: 'errorInfo',
    canceledInfo: 'canceledInfo',
    processingInfo: 'processingInfo',
    failedInfo: 'failedInfo',
    waitingProviderInfo: 'waitingProviderInfo',
    waitingMerchantInfo: 'waitingMerchantInfo',
  },
  manageScheduledReports: {
    title: 'Manage scheduled reports',
    sendButton: 'Yes, delete',
    back: 'No, go back',
    error: 'Unable to delete scheduled report. Try again.',
    success: 'Report delivery deleted.',
    descriptionEmpty: 'No scheduled reports. You can schedule them on Transactions page.',
    reportName: 'Report',
  },
  downloadDaysOfWeek: {
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
  },
  reconciliation: {
    title: 'Reconciliation',
    description: 'Download files to perform reconciliations faster and more efficiently.',
    buttons: {
      downloadFile: 'Download file',
    },
  },
  downloadModal: {
    titleHeader: 'Email report now',
    description: 'The file containing the filtered data will be sent to your corporate email.',
    fieldEmail: 'Your email',
    fieldFileFormat: 'File format',
    sendButton: 'Send',
    cancelButton: 'Cancel',
  },
  scheduleModal: {
    titleHeader: 'Schedule delivery',
    description: 'Receive the file in your email',
    fieldFileFormat: 'File format',
    fieldFrequency: 'Frequency',
    subFieldFrequency: 'Recurs every week on:',
    fieldDelivery: 'Delivery time',
    fieldEmail: 'Email',
    sendButton: 'Schedule',
    cancelButton: 'Cancel',
  },
  workflow: {
    noAdapters: 'No adapters available for this country.',
    newWorkflowDialog: {
      dialogTitle: 'New Workflow',
      workflowTitleLabel: 'Title',
      workflowTitlePlaceholder: 'Enter workflow title',
      workflowTitleError: 'Title is required',
      paymentMethodError: 'Payment method is required',
      paymentMethodLabel: 'Payment method',
      paymentMethodPlaceholder: 'Select an option',
      confirmButton: 'Save',
      cancelButton: 'Cancel',
    },
  },
  createWorkflowPage: {
    selectedPaymentMethod: 'Payment method:',
    publishWorkflow: 'Publish',
    publishWorkflowSuccess: 'Workflow published successfully!',
    publishWorkflowError: 'Failed to publish workflow.',
    addProviderDialog: {
      title: 'Select a provider',
      saveButton: 'Save',
      emptySelect: 'Please select a provider before adding.',
      providerAlreadyAdded: 'Provider {providerName} already added',
      errorMessage: 'No adapters available. Select a different country.',
    },
    providerCard: {
      succeeded: 'Succeeded',
      declined: 'Declined',
      error: 'Error',
    },
    connectProvidersDialog: {
      createTitle: 'Create adapter',
      editTitle: 'Edit adapter',
      tooltip: 'Duplicate',
      selectPlaceholder: 'Target value',
      button: {
        primary: 'Save',
        secondary: 'Cancel',
      },
      errorFetchingPayload: 'Error fetching provider payload.',
      invalidInput: 'Invalid input.',
      noPayloadAvailable: 'No payload available.',
    },
    removeProviderDialog: {
      title: 'Remove provider',
      content: 'Are you sure you want to remove this provider?',
      button: {
        primary: 'Remove',
        secondary: 'Cancel',
      },
    },
    removeEdgeDialog: {
      title: 'Remove connection',
      content: 'Are you sure you want to remove this connection?',
      button: {
        primary: 'Remove',
        secondary: 'Cancel',
      },
    },
    editOrRemoveDialog: {
      title: 'Edit or remove?',
      content: 'Would you like to edit the connection between the two providers or remove it?',
      button: {
        primary: 'Edit',
        secondary: 'Remove',
      },
    },
  },
  paymentMethod: {
    BANK: 'Bank',
    CASH: 'Cash',
    CREDIT: 'Credit',
    CREDIT_CARD: 'Credit Card',
    DEBIT_CARD: 'Debit Card',
    PIX: 'Pix',
    WALLET: 'Wallet',
  },
};

export default en;
